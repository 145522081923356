<template>
  <section>
    <v-row>
      <v-col cols="6">
        <v-card class="mt-4">
          <v-card-title>Sidebar Banner</v-card-title>
          <v-card-subtitle>Banner din partea stanga a paginii(sub filtre).</v-card-subtitle>
          <v-card-actions>
            <v-btn
              color="primary"
              class="primary-color"
              text
              :to="{name:'pm-category-sidebar-banner', params:{id:categoryId}}"
            >
              Editeaza
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="mt-4">
          <v-card-title>Top Banner</v-card-title>
          <v-card-subtitle>Banner din partea de sus a paginii.</v-card-subtitle>
          <v-card-actions>
            <v-btn
              color="primary"
              class="primary-color"
              text
              :to="{name:'pm-category-top-banner', params:{id:categoryId}}"

            >
              Editeaza
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

  </section>
</template>

<script>
export default {
  name: 'Banners',
  computed: {
    categoryId() {
      return this.$route.params.id
    },
  }
}
</script>
